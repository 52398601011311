import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {}
  },

  content: {
    padding: theme.spacing(4, 0),
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,

    "& article": { width: "100%" },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 0)
    }
  }
}))
