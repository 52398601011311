import { Grid } from "@material-ui/core"
import Img, { FluidObject } from "gatsby-image"
import * as React from "react"
import { FC } from "react"
import theme from "../../../App.theme"
import { DividedSection, SectionContainer, SectionTitle } from "../../_layout"
import Content from "../../homepage/ThanksSection/Content"

import useStyles from "./styles"


interface IConsejoNacionalSection {
  sectionID: string
  title: string
  img: FluidObject,
  alt: string
  content: string
  noButton?: boolean
}

const AcercaDeLaPlataforma: FC<IConsejoNacionalSection> = ({
                                                             sectionID,
                                                             title,
                                                             img,
                                                             alt,
                                                             content,
                                                             noButton
                                                           }) => {

  const styles = useStyles()

  return (
    <SectionContainer { ...{ sectionID } }>
      <DividedSection
        components={ [
          <Grid container direction={ "column" } style={ { padding: theme.spacing(2) } }>
            <Grid item>
              <SectionTitle { ...{ title } } />
            </Grid>
            <Grid item xs container className={ styles.imgRoot }>
              <Grid item container className={ styles.imgContainer }>
                <Img className={ styles.img } fluid={ img } { ...{ alt } } />
              </Grid>
            </Grid>
          </Grid>,
          <Content { ...{ content, title, noButton } } />
        ] }
      />
    </SectionContainer>
  )
}

export default AcercaDeLaPlataforma
