import * as React from "react"
import { FC } from "react"
import { ISectionContentExtended } from "../../../pages/acerca"
import { DividedSection, SectionContainer, SectionText, SectionTitle } from "../../_layout"
import Content from "../../homepage/ThanksSection/Content"
import Sponsors from "../../homepage/ThanksSection/Sponsors"
import { ISponsorImage } from "../../homepage/ThanksSection/ThanksSection"


interface IConsejoNacionalSection {
  sectionID: string
  consejo: ISectionContentExtended
  miembrosConsejo: ISponsorImage[]
}

const ConsejoNacionalSection: FC<IConsejoNacionalSection> = ({
                                                               sectionID,
                                                               consejo,
                                                               miembrosConsejo
                                                             }) => {

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title={ consejo.titulo } />
      <SectionText text={ consejo.intro } />
      <DividedSection
        reversed
        components={ [
          <Sponsors sponsors={ miembrosConsejo } />,
          <Content content={ consejo.contenido } noButton title={ consejo.titulo } />
        ] }
      />
      <SectionText text={ consejo.notas } />
    </SectionContainer>
  )
}

export default ConsejoNacionalSection
