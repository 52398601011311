import * as React from "react"
import { FC } from "react"
import { ISectionContentImage } from "../../../pages"
import { TextImageSection } from "../../_layout"

interface IInclusionSection {
  sectionID: string
  inclusion: ISectionContentImage
}

const InclusionSection: FC<IInclusionSection> = ({ sectionID, inclusion }) => {

  return (
    <TextImageSection
      image={ inclusion.image }
      reversed
      title={ inclusion.titulo }
      content={ inclusion.contenido }
      alt={ inclusion.alt }
      { ...{ sectionID } }
    />
  )
}

export default InclusionSection
