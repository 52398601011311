import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Grid } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { ISectionContent } from "../../../pages/acerca"
import { SectionContainer, SectionTitle } from "../../_layout"
import rawOptions from "../rawOptions"

import useStyles from "./styles"

interface IPoliticaNacionalSection {
  sectionID: string
  politica: ISectionContent
}

const PoliticaNacionalSection: FC<IPoliticaNacionalSection> = ({
                                                                 sectionID,
                                                                 politica
                                                               }) => {
  const styles = useStyles()

  const contenidoBodyJSON = JSON.parse(politica.contenido)

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title={ politica.titulo } />
      <Grid item container className={ styles.content }>
        <article>
          { documentToReactComponents(contenidoBodyJSON, rawOptions) }
        </article>
      </Grid>
    </SectionContainer>
  )
}

export default PoliticaNacionalSection
