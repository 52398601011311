import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"
import * as React from "react"
import { Carousel } from "../components/_common"
import { ICardItem } from "../components/_common/Card/Card"
import { ICarouselSlide } from "../components/_common/Carousel/Carousel"
import DidYouKnow, { IDidYouKnow } from "../components/_common/DidYouKnow/DidYouKnow"

import { Layout, SectionContainer, TextImageSection } from "../components/_layout"
import { AcercaDeLaPlataforma } from "../components/AcercaModule"
import { Contact } from "../components/ContactModule"
import {
  Cookies,
  DataSection,
  Documentos,
  MapsSection,
  Newsletter, NewsSection,
  TargetsSection,
  VideoSection
} from "../components/homepage"
import { ISponsorImage } from "../components/homepage/ThanksSection/ThanksSection"
import { ISectionContent } from "./acerca"

const getHomepageautor = graphql`
    query {
        carouselData: allContentfulCarruselElemento(
            sort: { fields: [orden], order: ASC }
        ) {
            edges {
                node {
                    id
                    titulo
                    url
                    textoMedio
                    textoInferior
                    imagen {
                        fluid(maxWidth: 4936, quality: 100) {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        sponsorData: allContentfulColaboradoresLogotipo(
            sort: { fields: [ordenDeAparicion], order: ASC }
            filter: { seccionAcercaDe: { eq: true } }
        ) {
            edges {
                node {
                    nombre
                    logotipo {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        newsData: allContentfulLigasDeInteresArticulo(
            limit: 8
            sort: { fields: [orden], order: ASC }
            filter: { pantallaDeInicio: { eq: true } }
        ) {
            edges {
                node {
                    id
                    titulo
                    subtitulo
                    url
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        didYouKnowData: allContentfulSabiasQueElemento {
            edges {
                node {
                    id
                    texto
                    contenido {
                        raw
                    }
                }
            }
        }

        dataImage: file(relativePath: { eq: "explorarDatos.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        introData: allContentfulContenidoHomeIntro {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        conocerMasData: allContentfulContenidoHomeDocumentos {
            edges {
                node {
                    titulo
                    contenido
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        acercaData: allContentfulContenidoAcercaDeAcercaDeEstaPlataforma {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        mapasData: allContentfulContenidoHomeMapas {
            edges {
                node {
                    titulo
                    contenido
                    urlDelMapa
                }
            }
        }

        cookiesData: allContentfulContenidoHomeCookiesContenidoTextNode {
            edges {
                node {
                    contenido
                }
            }
        }
    }
`

export interface ISectionContentImage extends ISectionContent {
  image: FluidObject
  alt: string
}

export interface ISectionContentMap extends ISectionContent {
  urlDelMapa: string
}

const Index: React.FC = () => {
  const {
    carouselData,
    sponsorData,
    newsData,
    dataImage,
    didYouKnowData,
    acercaData,
    introData,
    conocerMasData,
    mapasData,
    cookiesData
  } = useStaticQuery(getHomepageautor)

  const slides: ICarouselSlide[] = carouselData.edges.map(({ node }: any) => ({
    id: node.id,
    image: node.imagen.fluid,
    title: node.titulo,
    url: node.url,
    textoSuperior: node.textoSuperior,
    textoMedio: node.textoMedio,
    textoInferior: node.textoInferior
  }))

  const sponsors: ISponsorImage[] = sponsorData.edges.map(({ node }: any) => ({
    nombre: node.nombre,
    image: node.logotipo.fluid,
    alt: node.nombre.split(" ").join("-").toLowerCase()
  }))

  const news: ICardItem[] = newsData.edges.map(({ node }: any) => ({
    id: node.id,
    titulo: node.titulo,
    subtitulo: node.subtitulo,
    url: node.url,
    imagen: node.imagen.fluid
  }))

  const didYouKnowTips: IDidYouKnow[] = didYouKnowData.edges.map(({ node }) => ({
    id: node.id,
    text: node.text,
    contenido: node.contenido.raw
  }))

  const intro: ISectionContentImage = introData.edges.map(({ node }: any) => ({
    ...getSectionData(introData),
    image: node.imagen.fluid,
    alt: node.titulo.split(" ").join("-").toLowerCase()
  }))[0]

  const conocerMas: ISectionContentImage = conocerMasData.edges.map(
    ({ node }: any) => ({
      titulo: node.titulo,
      contenido: node.contenido,
      image: node.imagen.fluid,
      alt: node.titulo.split(" ").join("-").toLowerCase()
    })
  )[0]

  const mapas: ISectionContentMap = mapasData.edges.map(({ node }: any) => ({
    titulo: node.titulo,
    contenido: node.contenido,
    urlDelMapa: node.urlDelMapa
  }))[0]
  const { contenido: cookies }: any = cookiesData.edges[0].node

  const acerca: ISectionContentImage = acercaData.edges.map(({ node }: any) => ({
    ...getSectionData(acercaData),
    image: node.imagen.fluid,
    alt: node.titulo.split(" ").join("-").toLowerCase()
  }))[0]

  return (
    <Layout withNav seoTitle="Inicio" seoDescription="PNIF">
      {/*<Cookies { ...{ cookies } } />*/}
      <Carousel { ...{ slides } } />
      <TextImageSection
        image={ intro.image }
        title={ intro.titulo }
        content={ intro.contenido }
        route="/pnif"
        imgRoute="/documentos"
        cta="Leer más"
        alt={ intro.alt }
        sectionID="intro-section"
      />
      <SectionContainer sectionID="video-section">
        <VideoSection videoYoutubeID="EIT6k5uHqqg" />
      </SectionContainer>

      <TargetsSection sectionID="target-section" />

      <DataSection image={ dataImage.childImageSharp.fluid } />

      <MapsSection
        mapURL={ mapas.urlDelMapa }
        content={ mapas.contenido }
        title={ mapas.titulo }
      />

      <Documentos
        image={ conocerMas.image }
        title={ conocerMas.titulo }
        content={ conocerMas.contenido }
        route="/documentos"
        cta="Ver documentos"
        alt="documentos-imagen"
        sectionID="home-documents-section"
      />

      <DidYouKnow sectionID="did-you-know-index" tips={ didYouKnowTips } />

      <NewsSection { ...{ news } } />

      <AcercaDeLaPlataforma
        sectionID={ "acerca-section" }
        title={ acerca.titulo }
        content={ acerca.contenido }
        img={ acerca.image }
        alt={ acerca.alt }
      />

      <Contact />

      <Newsletter sectionID={ "homepage-newsletter" } />
    </Layout>
  )
}

export default Index

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    titulo: node.titulo,
    contenido: node.contenido.raw
  }))[0]
