import * as React from "react"
import { FC } from "react"
import { ISectionContentExtended } from "../../../pages/acerca"
import { DividedSection, SectionContainer, SectionText, SectionTitle } from "../../_layout"
import Content from "../../homepage/ThanksSection/Content"
import Sponsors from "../../homepage/ThanksSection/Sponsors"
import { ISponsorImage } from "../../homepage/ThanksSection/ThanksSection"


interface IComiteSection {
  sectionID: string
  comite: ISectionContentExtended
  comiteEducacionLogos: ISponsorImage[]
}

const ComiteSection: FC<IComiteSection> = ({
                                             sectionID,
                                             comite,
                                             comiteEducacionLogos
                                           }) => {
  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title={ comite.titulo } />
      <SectionText text={ comite.intro } />
      <DividedSection
        components={ [
          <Sponsors sponsors={ comiteEducacionLogos } />,
          <Content content={ comite.contenido } noButton title={ comite.titulo } />
        ] }
      />
      <SectionText text={ comite.notas } />
    </SectionContainer>
  )
}

export default ComiteSection
